import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"
import { CheckList, Footer, Header } from "../components"
import { ImageCloud } from "../components/image-cloud/image-cloud"
import { Quote, Video } from "../elements"
import { BodyText, Display, Highlight, Lead, Title } from "../identity"
import { Blue } from "../identity/typography/blue"
import { Container, OverlappingImage } from "../layout"
import { theme } from "../theme"

const HomePage = () => {
  const {
    pos: {
      childImageSharp: { fixed: pos },
    },
    terminal: {
      childImageSharp: { fixed: terminal },
    },
    label: {
      childImageSharp: { fixed: label },
    },
    slip: {
      childImageSharp: { fixed: slip },
    },
    scan: {
      childImageSharp: { fixed: scan },
    },
    screen: {
      childImageSharp: { fixed: screen },
    },

    diego: {
      childImageSharp: { fixed: diego },
    },
  } = useStaticQuery(graphql`
    query {
      diego: file(relativePath: { eq: "subsidia/diego.jpeg" }) {
        childImageSharp {
          fixed(width: 600, height: 411, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      pos: file(relativePath: { eq: "subsidia/subsidia-pos-screen.png" }) {
        childImageSharp {
          fixed(width: 468, height: 313) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      terminal: file(
        relativePath: { eq: "subsidia/verkauf-an-stationaerer-kasse.png" }
      ) {
        childImageSharp {
          fixed(width: 579, height: 387) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      label: file(relativePath: { eq: "subsidia/kleider-etikett.jpeg" }) {
        childImageSharp {
          fixed(width: 468, height: 313) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      slip: file(relativePath: { eq: "subsidia/beleg-drucker.jpeg" }) {
        childImageSharp {
          fixed(width: 468, height: 313) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      scan: file(relativePath: { eq: "subsidia/pwa-etikett-scan.png" }) {
        childImageSharp {
          fixed(width: 579, height: 387) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      screen: file(
        relativePath: { eq: "subsidia/pwa-etikett-scan-screen.png" }
      ) {
        childImageSharp {
          fixed(width: 468, height: 313) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>Subsidia: Digitalisierung der Lifestyle-Branche.</title>
        </Helmet>
        <Normalize />
        <Header subheading="Kategorien Innovation &amp; Technology" />
        <Container>
          <Display>
            <Highlight>Subsidia:</Highlight> Digitalisierung der
            Lifestyle-Branche<Blue>.</Blue>
          </Display>
          <Lead>
            Subsidia digitalisiert mit innovativen und modernen Apps den
            Einzelhandel in der Modebranche. Aktuell mit zwei Apps: eine zur
            Unterstützung in der Verkaufsberatung und eine weitere für eine
            effiziente Warenbewirtschaftung.
          </Lead>
          <Lead>
            Diese Apps wurden in einem interdisziplinären Team in iterativen
            Sprints entwickelt und getestet. Der Einsatz modernster
            Webtechnologien wie PWA, React oder Storybook stellt eine
            zukunftsgerichtete Lösung sicher. Dank der den Applikationen
            zugrunde liegenden Micro-Service- und Micro-App-Architektur in einer
            Cloud-Umgebung ist Subsidia auch für die Zukunft bestens gerüstet.
          </Lead>
        </Container>

        <ImageCloud images={[pos, terminal, label, slip, scan, screen]} />

        <Container>
          <Title number="01">
            Webtechnologien neu entdeckt<Blue>.</Blue>
          </Title>

          <BodyText>
            Apps für alle mobile Plattformen und das Web zur Verfügung zu
            stellen ist eine Herausforderung. Hier helfen Progressive Web Apps —
            kurz PWA. Anstatt für jede Plattform eine native Technologie zu
            verwenden, kann heutzutage mit modernen Browsern nahezu die gleiche
            Funktionalität offeriert werden. Und das mit nur einer Codebasis
            anstatt dreien. Der moderne Technologie-Stack mit React als
            UI-Technologie, Google Cloud Builds für effiziente Builds und Gitlab
            CI für Continuous Integration erlaubt die Veröffentlichung von neuen
            App-Versionen innert Minuten — ohne langwierige App Store-Reviews.
          </BodyText>

          <BodyText>
            Dennoch muss auf nichts verzichtet werden. Die Smartphone-Kamera
            dient als Barcode-Scanner, das SIX Zahlungsterminal und der
            Belegdrucker werden direkt aus der Web App angesprochen — was bis
            vor kurzem nativen Apps vorbehalten war.
          </BodyText>
        </Container>

        <Quote author="Diego Goldener, CEO Subsidia" photo={diego}>
          &laquo;Unter Einsatz modernster Webtechnologien konnten wir sehr
          schnell ein MVP auf den Markt bringen und Kunden gewinnen — und das
          mit einem überschaubar kleinen Team.&raquo;
        </Quote>

        <Container>
          <Title number="02">
            Die Kasse bringt Bewegung ins Kauf&shy;gespräch<Blue>.</Blue>
          </Title>

          <BodyText>
            Bei der Verkaufsberatung ist nicht nur die Kasse in der Hosentasche,
            sondern sämtliche Informationen zu den Lagerbeständen. Die Frage
            &laquo;Gibt es dieses Hemd auch in M?&raquo; ist schnell
            beantwortet. Sitzt das Hemd, wird der Barcode per Smartphone-Kamera
            gescannt und das Produkt direkt in den Warenkorb gelegt — alles ohne
            Zwischenstopp an einer stationären Kasse.
          </BodyText>
          <BodyText>
            Die mobile Kassen-App kann problemlos mit Gutscheinen, Retouren und
            mehreren Währungen umgehen, auch innerhalb einer Transaktion. Die
            Kommunikation zum SIX Zahlungsterminal und dem Belegdrucker
            funktioniert drahtlos, sicher und schnell.
          </BodyText>
        </Container>

        <Container wide>
          <OverlappingImage>
            <Video id="1lnsbXrI0vNMbIASufxtqPbCWHxi9-ygS" />
          </OverlappingImage>
        </Container>

        <Container>
          <Title number="03">
            Papierlose Warenbewirtschaftung<Blue>.</Blue>
          </Title>

          <BodyText>
            Dank der Backoffice-App von Subsidia wird die Warenbewirtschaftung
            beinahe papierlos. Lieferscheine können per Kamera gescannt, direkt
            geprüft und entweder bestätigt oder korrigiert werden.
            Herstellerspezifische Grössen und Warengruppen sind standardisiert
            hinterlegt und können so einfach verwaltet werden. Die komplexe
            EDI-Anbindung ist in den digitalen Warenfluss von Subsidia
            eingebaut. So können auch kleine Boutiquen von
            Supplychain-übergreifenden Prozessen profitieren.
          </BodyText>
        </Container>

        <Container wide>
          <OverlappingImage>
            <Video id="1ll3KgBSa-aVTozHyhf5SbtkPLOht9llO" />
          </OverlappingImage>
        </Container>

        <Container>
          <Title number="04">
            Bereit für die Zukunft<Blue>.</Blue>
          </Title>

          <BodyText>
            Dank einem Software-as-a-Service (SaaS) Ansatz ist das Onboarding
            neuer Kunden innert Minuten möglich. Die Funktionalität ist in
            unterschiedliche Apps unterteilt und kann in Zukunft durch weitere
            ergänzt werden.
          </BodyText>
          <BodyText>
            Die klare Trennung der RESTful APIs und der Single Page Client-Apps
            stellt auch sicher, dass in Zukunft Teile der Applikation ohne
            Problem ausgetauscht oder erneuert werden können. Damit ist auch die
            Gefahr einer einzigen, grossen Legacy-Applikation sehr viel kleiner.
          </BodyText>
          <BodyText>
            Subsidia ist von Haus aus erweiterbar. Daten können über eine Bridge
            (mit RabbitMQ) in das System gespielt werden. Handkehrum ist es ohne
            Problem möglich, Daten über die APIs zu konsumieren. Somit ist die
            Interoperabilität mit der existierenden IT-Landschaft gewährleistet.
          </BodyText>
        </Container>

        <Footer />
      </>
    </ThemeProvider>
  )
}

export default HomePage
